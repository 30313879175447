import type { Prettify } from '../../../front/src/types/utils';

type KeysToSwapMapping<
  T extends Record<string, any>,
  S extends Partial<Record<keyof T, string>>,
> = {
  [P in keyof S as S[P] extends string ? S[P] : never]: P extends keyof T ? T[P] : never;
};

/**
 * Returns the same object swaping any specific key name(s) to another to avoid collisions
 *
 * @param objectToHandle
 * @param keysToSwap
 *
 * @example
 * const myObject = { id: 12345, name: 'foo', location: 'bar' };
 *
 * const newObject = objectKeySwap(myObject, { id: 'unicorn', location: 'place' });
 * // { unicorn: 12345, name: 'foo', place: 'bar' }
 *
 */
export const objectKeySwap = <
  T extends Record<string, any>,
  Kts extends Partial<Record<keyof T, string>>,
>(
  objectToHandle: T,
  keysToSwap: Kts,
): Prettify<Omit<T, keyof Kts> & KeysToSwapMapping<T, Kts>> => {
  if (typeof objectToHandle !== 'object' || Array.isArray(objectToHandle)) {
    throw new Error('[objectKeySwap] This is not an object');
  }

  const result = {} as Omit<T, keyof Kts> & KeysToSwapMapping<T, Kts>;

  for (const key in objectToHandle) {
    if (key in keysToSwap) {
      const newKey = keysToSwap[key] as string;
      result[newKey] = objectToHandle[key];
    } else {
      result[key as string] = objectToHandle[key];
    }
  }

  return result;
};

/**
 * Returns the same object without specific key(s)
 *
 * @example
 * const originalObject = { a: 1, b: 2, c: 3 };
 * const modifiedObject = objectKeyRemove(originalObject, 'b');
 * // { a: 1, c: 3 }
 *
 * const modifiedObject = objectKeyRemove(originalObject, ['b', 'c']);
 * // { a: 1 }
 */
export const objectKeyRemove = <T extends Record<string, any>, Kntr extends keyof T>(
  objectToHandle: T,
  keyNameToRemove: Kntr | Kntr[],
): Omit<T, Kntr> => {
  const keysArray = Array.isArray(keyNameToRemove) ? keyNameToRemove : [keyNameToRemove];

  return Object.fromEntries(
    Object.entries(objectToHandle).filter(([key]) => !keysArray.includes(key as Kntr)),
  ) as Omit<T, Kntr>;
};

/**
 * Invert each key/value of an object
 *
 * @param object
 *
 * @example
 * const obj = { a: 'b', c: 'd' };
 *
 * const swappedObj = swapObjectKeyValues(obj);
 * // { b: 'a', d: 'c' }
 */
export function objectKeyValuesSwap<T extends Record<string, string>>(
  object: T,
): { [K in keyof T as T[K]]: K } {
  return Object.fromEntries(Object.entries(object).map(([k, v]) => [v, k])) as {
    [K in keyof T as T[K]]: K;
  };
}
