import React, { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { LimitedRelativeTime } from '../../components/LimitedRelativeTime';
import { Link } from '../../components/Link';
import { ReactComponent as IconCommentsBubbleBlue } from '../../icons/icon-comments-blue-double.svg';
import type { SimilarArticleBlockData } from '../../types/layout';
import { html } from '../../utils/dom';

const messages = defineMessages({
  commentsCount: {
    defaultMessage: `{count, plural,
    =0 {aucun commentaire}
    =1 {1 commentaire}
    other {# commentaires}
  }`,
    id: '2rD8vA',
  },
  relatedArticles: { defaultMessage: 'Articles similaires', id: 'g6w3vr' },
});

interface BlockProps {
  block: SimilarArticleBlockData;
}

export const BlockArticle: React.FC<BlockProps> = memo(({ block }) => {
  const customTitle = block.options?.customTitle;
  return (
    <>
      {block?.data?.length > 0 ? (
        <div className="BlockArticle flex flex-col space-y-4 md:mt-16">
          <div className="text-md font-roboto font-semibold">
            {customTitle || <FormattedMessage {...messages.relatedArticles} />}
          </div>
          <ul className="layout-related-articles-list divide-y-[1px] divide-gray-100">
            {block.data &&
              block.data.filter(Boolean).map(page => (
                <li key={page._id} className="py-[9px] space-y-[2px] flex flex-col overflow-hidden">
                  <Link to="topic" params={{ slug: page.slug }}>
                    <a
                      className="truncate hover:text-primary"
                      title={html(page.subject! as string) as string}
                      href=""
                    >
                      {html(page.subject as string)}
                    </a>
                  </Link>
                  <div className="flex items-baseline justify-between">
                    <span className="flex space-x-[5px] items-baseline">
                      <IconCommentsBubbleBlue className="w-4 h-4 self-center" />
                      {/* <i className="icon-messages w-4 h-4 self-center" /> */}
                      <span className="text-[13px] text-gray-400">{page.count_comments || 0}</span>
                    </span>
                    <span className="text-xs text-gray-400 font-medium">
                      <LimitedRelativeTime date={page.content_edited_at || page.created_at} />
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </>
  );
});
