import type { PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';

import type { ButtonV3Props } from './ButtonV3';
import { ButtonV3 } from './ButtonV3';

export interface IconButtonV3Props extends ButtonV3Props {
  icon: ReactNode;
}

export const ButtonV3Icon = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  PropsWithChildren<IconButtonV3Props>
>(({ icon, children, ...buttonProps }, ref) => {
  return (
    <ButtonV3 ref={ref} radius="rounded-md" isIcon {...buttonProps}>
      <div className="flex items-center justify-center gap-x-[7px] leading-none">
        {icon}
        {children && <span>{children}</span>}
      </div>
    </ButtonV3>
  );
});
