const paletteV3 = {
  white: '#FFFFFF',
  bronze: {
    100: '#D97219',
    200: '#D97219',
    250: '#D97219',
    300: '#D97219',
    400: '#D97219',
  },
  yellow: {
    100: '#FFEB99',
    200: '#FFCE00',
    250: '#E6A000',
    300: '#FF8500',
    400: '#A55F00',
  },
  red: {
    60: '#FFF0ED', // tags bg red in Figma (v4)
    100: '#FFD9D9',
    200: '#FFA0A0',
    250: '#F44545', // Main red in Figma (v4)
    300: '#F54C3C',
    350: '#F66969',
    // 400: '#E30000',
    380: '#D03418', // Dark red in Figma (v4)
    400: '#D02C1E',
  },
  blue: {
    100: '#EDF9FF',
    200: '#BEE6FA',
    250: '#1EAAE1',
    300: '#2980B9',
    400: '#0A259C',
  },
  gray: {
    50: '#F8F8F8',
    51: '#F7F7F7',
    60: '#F5F5F5', // tags bg gray in Figma (v4)
    100: '#F2F2F2',
    199: '#ECECEC',
    200: '#EBEBEB',
    210: '#E9E9E9',
    250: '#DDDDDD',
    280: '#C0C0C0',
    300: '#BFBFBF',
    350: '#B7B7B7',
    360: '#BEBEBE',
    380: '#A8A8A8',
    400: '#A0A0A0',
    450: '#9FA09F',
    480: '#76767E', // gray-lightv4 - Light gray in Figma (v4)
    500: '#6A6A6A',
    530: '#4B4B4B',
    550: '#595959', // gray-soft - Soft gray in Figma (v4)
    600: '#363636', // gray-darkv4 and gray (default) - Dark gray in Figma (v4)
    602: '#343434',
    700: '#2a2929',
    800: '#101010',
  },
  green: {
    100: '#8CCD32',
    200: '#7CB72A',
    300: '#467846',
    400: '#304530',
  },
};

const { white, red, blue, yellow, green, gray, bronze } = paletteV3;

module.exports = {
  white,
  black2: '#000000',
  black: gray['600'],
  success: green['300'],
  failure: red['400'],
  bookmark: yellow['100'],
  warning: yellow['300'],
  red: {
    ...red,
    DEFAULT: red['250'],
  },
  blue: {
    ...blue,
    DEFAULT: blue['200'],
  },
  yellow: {
    ...yellow,
    DEFAULT: yellow['100'],
  },
  bronze: {
    ...bronze,
    DEFAULT: bronze['400'],
  },
  gray: {
    ...gray,
    lighter: gray['100'],
    lightest: gray['100'],
    light: gray['100'],
    semiLight: gray['200'],
    medium2: gray['250'],
    medium1: gray['300'],
    medium: gray['400'],
    semiDark: gray['500'],
    semiDark2: gray['550'],
    DEFAULT: gray['600'],
    900: gray['700'],
    dark: gray['700'],
    lightv4: gray['480'],
    soft: gray['550'],
    darkv4: gray['600'],
    silver: gray['400'],
    graphite: gray['530'],
  },
  green: {
    ...green,
    DEFAULT: green['200'],
  },
  primary: {
    light: red['100'],
    variant: red['200'],
    text: red['250'],
    base: red['250'],
    DEFAULT: red['250'],
    dark: red['380'],
  },
  secondary: {
    light: blue['100'],
    variant: blue['200'],
    text: blue['300'],
    base: blue['300'],
    DEFAULT: blue['300'],
  },
  typography: {
    black2: '#000000',
    black: gray['600'],
    base: gray['600'],
    DEFAULT: gray['600'],
    primary: red['250'],
  },
  input: {
    bg: white,
    error: red['200'],
    selected: blue['300'],
    text: gray['600'],
    border: gray['250'],
    editorborder: gray['199'],
    editorbg: gray['51'],
  },
  grayToRemove: {
    c1: gray['300'],
    e9: gray['300'],
  },
  chat: {
    bg: blue['300'],
  },
};
