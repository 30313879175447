import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';

import { linkMessages } from '../../../front/src/i18n/link';
import { ReactComponent as GraphDebateBrand } from '/public/illustrations/graphdebate-brand.svg';

export const PoweredByGraphDebateBtn = () => {
  const { formatMessage } = useIntl();

  return (
    <Link
      className="PowerByGraphDebateBtn p-1 flex gap-x-2 justify-center items-center group"
      href={formatMessage(linkMessages.graphDebateProduct)}
      target="_blank"
    >
      <div className="text-[13px] text-gray-lightv4">Powered by</div>
      <GraphDebateBrand className="h-[17px] text-gray-silver group-hover:text-gray-semiDark" />
    </Link>
  );
};

export const PoweredByGraphDebateBlock = () => (
  <div className="PowerdByGraphDebateBlock mt-2 flex justify-end">
    <PoweredByGraphDebateBtn />
  </div>
);
