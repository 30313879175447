import { defineMessages } from 'react-intl';

export const linkMessages = defineMessages({
  graphComment: {
    defaultMessage: 'https://www.graphcomment.com/fr',
    id: '0t0p4W',
  },
  graphDebateProduct: {
    defaultMessage: 'https://www.graphcomment.com/fr/products/graphdebate/features',
    id: 'POeP1t',
  },
  cgu: {
    defaultMessage: 'https://www.graphcomment.com/fr/conditions',
    id: 'uiWWJX',
  },
});
