import type { CSSProperties, FC } from 'react';
import { memo } from 'react';

import type { AvatarSizes } from '../components/Avatar';
import { Avatar } from '../components/Avatar';
import type { RequestFriendCallback } from '../contributor/api/relation';
import { getPseudo } from '../contributor/Ban/ban-utils';
import type { ProfileAvatarPropTypes } from '../contributor/Profile/ProfileAvatarPopover';
import { ProfileAvatarPopover } from '../contributor/Profile/ProfileAvatarPopover';
import type { Author, Guest, Profile } from '../types/api';

interface Props extends Omit<ProfileAvatarPropTypes, 'picture' | 'userId'> {
  author: Author | undefined;
  userId: string | undefined;
  profile: Profile | undefined;
  withProfilePopover?: boolean;
  size?: AvatarSizes;
  fullSize?: boolean;
  smallAvatar?: boolean;
  inviteCallback?: RequestFriendCallback;
  isGroup?: boolean;
  style?: CSSProperties;
}

export const AuthorAvatar: FC<Props> = memo(function AuthorAvatar(props) {
  const {
    author,
    userId,
    profile,
    withProfilePopover,
    fullSize,
    smallAvatar,
    size,
    inviteCallback,
    isGroup,
    ...others
  } = props;

  const pseudo = getPseudo(author) || '';
  const picture = profile?.picture ?? (author as Guest)?.picture;

  const avatarProps = {
    type: author?.__typename,
    pseudo,
    profile,
    picture,
    size: size ? size : fullSize ? 'full' : smallAvatar ? 'small2' : 'medium',
    noRound: isGroup,
    ...others,
  };

  return withProfilePopover ? (
    <ProfileAvatarPopover userId={userId} inviteCallback={inviteCallback} {...avatarProps} />
  ) : (
    <Avatar {...avatarProps} />
  );
});
