import type { ComponentType, FC } from 'react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

import useElementSize from '../../hooks/useElementSize';
import type { LayoutBlock } from '../../types/layout';
import { Block } from './Block';
import { isBlockToDisplayOnDesktop, isBlockToDisplayOnMobile } from './blockCheck';
import { BoxContainer, type LayoutContainerProps } from './layoutContainers';

interface Props {
  layout?: LayoutBlock[];
  container: ComponentType<LayoutContainerProps>;
  containerClassName?: string;
  shouldDisplaySlidableContainer?: boolean;
}

export const AreaBlocks: FC<Props> = memo(function AreaBlocks(props) {
  let {
    layout = [],
    container: Cmp,
    containerClassName,
    shouldDisplaySlidableContainer,
    ...blockProps
  } = props;

  // Hide area if all blocks are hidden on mobile or desktop
  const shouldHideAreaOnMobile = layout.every(block => !isBlockToDisplayOnMobile(block));
  const shouldHideAreaOnDesktop = layout.every(block => !isBlockToDisplayOnDesktop(block));
  const shouldHideArea = layout.length === 0 || (shouldHideAreaOnMobile && shouldHideAreaOnDesktop);

  return !shouldHideArea ? (
    <Cmp
      className={twMerge(
        containerClassName,
        shouldHideAreaOnMobile && '_md:hidden',
        shouldHideAreaOnDesktop && 'md:hidden',
      )}
      area={layout?.[0]?.locate}
      {...(shouldDisplaySlidableContainer && {
        isSlidableContainer: shouldDisplaySlidableContainer,
      })}
    >
      <LayoutBlocks layoutContent={layout} {...blockProps} />
    </Cmp>
  ) : null;
});

export const LayoutBlocks = ({ layoutContent }: { layoutContent: LayoutBlock[] }) => {
  const { height: stickyOffset } = useElementSize('NavbarHeader');
  const hasStickyContent = layoutContent?.some(block => block.sticky);

  if (!hasStickyContent) {
    return (
      <>
        {layoutContent?.map(block => (
          <Block key={block?._id} block={block} />
        ))}
      </>
    );
  }

  return (
    <BoxContainer className={'md:sticky md:z-10'} style={{ top: `${stickyOffset || 0}px` }}>
      {layoutContent?.map(block => (
        <Block key={block?._id} block={{ ...block, sticky: false }} />
      ))}
    </BoxContainer>
  );
};
