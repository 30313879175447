// Node 18 is required to build.
// In the meanwhile, _document declares google fonts (GraphDebate and GraphLogin).
import { Inter, Source_Sans_3 } from 'next/font/google';

const sourceSans = Source_Sans_3({
  subsets: ['latin'],
  variable: '--font-source-sans',
});

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
});

export function FontFamily() {
  return (
    <style jsx global>{`
      :root {
        --font-source-sans: ${sourceSans.style.fontFamily};
        --font-inter: ${inter.style.fontFamily};
      }

      html {
        font-family: var(--font-source-sans);
      }
    `}</style>
  );
}
