import type { FC } from 'react';
import { memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { usePermissions } from '../../hooks/usePermissions';
import { ReactComponent as IconNewArticle } from '../../icons/icon-new-article.svg';
import { ReactComponent as IconTopicNew } from '../../icons/icon-topic-new-pen.svg';
import { NoSSR } from '../../utils/ssr';
import { Tooltip } from '../GraphModal/Tooltip';
import type { LinkProps } from '../Link';
import { Link } from '../Link';
import type { ButtonV3Props } from './ButtonV3';
import { ButtonV3Icon } from './ButtonV3Icon';

const messages = defineMessages({
  createCTA: { defaultMessage: 'Nouveau sujet', id: 'z+Six7' },
});

interface Props extends Omit<LinkProps, 'href' | 'to'> {
  className?: string;
  params?: Record<string, string>;
  mainContainerClass?: string;
  buttonRadius?: ButtonV3Props['radius'];
}

export const CreateTopicButton: FC<Props> = memo(function CreateTopicButton(props) {
  const { className, mainContainerClass, buttonRadius, params, ...others } = props;
  const { authUserId, isAuthDone } = usePermissions();

  return (
    <NoSSR>
      {authUserId && isAuthDone ? (
        <div className={twMerge('CreateTopicButton h-[50px]', mainContainerClass)}>
          <Link to="write" passHref params={params} {...others}>
            <a>
              <ButtonV3Icon
                as="link"
                variant="contained"
                color="primary"
                className={className}
                icon={<IconTopicNew className="text-white group-button-hover:text-primary" />}
                radius={buttonRadius}
              >
                <span className="text-[14px] font-semibold">
                  <FormattedMessage {...messages.createCTA} />
                </span>
              </ButtonV3Icon>
            </a>
          </Link>
        </div>
      ) : null}
    </NoSSR>
  );
});

export const CreateTopicIconButton: FC = memo(function CreateTopicIconButton() {
  const { authUserId, isAuthDone } = usePermissions();

  return authUserId && isAuthDone ? (
    <div className="flex items-center">
      {authUserId && isAuthDone && (
        <Tooltip
          placement="bottom"
          interactive
          content={<FormattedMessage {...messages.createCTA} />}
        >
          <div>
            <Link to="write" params={{}}>
              <a className="block p-2 -mr-2">
                <IconNewArticle />
              </a>
            </Link>
          </div>
        </Tooltip>
      )}
    </div>
  ) : null;
});
