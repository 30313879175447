import type { QueryHookOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { objectKeyValuesSwap } from '../../../../graphdebate/src/utils/objects';
import { CHILD_LIMIT, UI_DEPTH } from '../../constants/message';
import type { QueryType } from '../../types/api';
import { AuthorFragment } from '../api/thread';

const threadMessagesSortType = { relevance: true, newest: true, oldest: true } as const;

export const threadMessagesSortTypeValue = (
  Object.keys(threadMessagesSortType) as Array<keyof typeof threadMessagesSortType>
).filter(k => threadMessagesSortType[k]);

export type ThreadMessagesSortType = keyof typeof threadMessagesSortType;

export type ThreadMessagesQueryVariables = {
  limit: number;
  slug: string;
  sort: ThreadMessagesSortType;
  depth: number;
  offset?: number;
};

const REDIRECT_SORT_API = {
  relevance: 'relevance',
  newest: 'newestLine',
  oldest: 'oldestLine',
} as const;

export type ALLOWED_SORTS = keyof typeof REDIRECT_SORT_API;

export const sortKeyMapApiToFront = objectKeyValuesSwap(REDIRECT_SORT_API);

export const useBuildThreadMessages = ({
  limit,
  slug,
  sort,
  offset,
  depth,
}: ThreadMessagesQueryVariables) => {
  const query = useMemo(() => generateQuery(depth, sort), [depth, sort]);
  const sortApi = REDIRECT_SORT_API[sort] ?? null;

  const options: QueryHookOptions = {
    variables: {
      slug,
      sort: sortApi,
      offset,
      limit,
    },
    skip: !sortApi,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  };

  return useQuery<QueryType>(query, options);
};

export const ThreadMessageFragment = gql`
  fragment ThreadMessageFragment on Message {
    id
    content
    status
    hide
    shadow
    createdAt
    contentEditedAt
    hiddenEmbeds
    embeds
    pinned
    result
    votes {
      score
      user {
        id
      }
    }
    author {
      ...AuthorFragment
    }
  }
  ${AuthorFragment}
`;

export const ChildMessageMetaFragment = gql`
  fragment ChildMessageMetaFragment on ChildMessages {
    meta {
      count
      page
      offset
      limit
    }
  }
`;

export const ChildMessageRelevanceFragment = gql`
  fragment ChildMessageRelevanceFragment on ChildMessages {
    relevanceMessageOrder {
      id
      result
    }
  }
`;

export const generateChildren = (depth: number, sort: string): string =>
  depth === 0
    ? ''
    : `childMessages(offset: 0, limit: ${CHILD_LIMIT}, sort: "${sort}") {
      ...ChildMessageMetaFragment
      ${sort !== 'relevance' ? '' : `...ChildMessageRelevanceFragment`}
      messages {
        id
        ...ThreadMessageFragment
        ${generateChildren(depth - 1, sort)}
      }
    }`;

export const generateParent = (depth: number, sort: string): string =>
  depth === 0
    ? ''
    : `parentMessage {
      ...ThreadMessageFragment
      ${generateChildren(UI_DEPTH, sort)}
      ${generateParent(depth - 1, sort)}
    }`;

export const generateRootMessage = (depth: number, sort: string) => `id
  ...ThreadMessageFragment
  ${generateChildren(depth, sort)}
  parentMessage {
    ...ThreadMessageFragment
  }
`;

// Beware: the arguments for childMessages() must match exactly what's queried in the cache here:
// packages/front/src/thread/api/message.ts
// so that it is found when reading the cache to insert new messages.
export const generateQuery = (depth: number, sort: string) => gql`
    query TopicMessagesQuery_${depth} ($slug: String, $sort: String, $offset: Int!, $limit: Int!) {
        website {
            id
            findTopic(slug: $slug) {
                id
                findOrCreateThread(
                    offset: $offset
                    limit: $limit
                    sort: $sort
                    forceSort: true
                ) {
                    id
                    countMessages
                    countRootMessages
                    childMessages(offset: $offset, limit: $limit, sort: $sort) {
                        ${sort === 'relevance' ? '...ChildMessageRelevanceFragment' : ''}
                        ...ChildMessageMetaFragment
                        messages {
                            ${generateRootMessage(depth, sort)}
                        }
                    }
                }
            }
        }
    }
    ${ThreadMessageFragment}
    ${ChildMessageMetaFragment}
    ${sort === 'relevance' ? ChildMessageRelevanceFragment : ''}
`;
