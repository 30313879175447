import Head from 'next/head';
import React, { memo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { BlockFeatured } from '../../../../graphdebate/src/components/Layout/BlockFeatured';
import { BlockLastTopics } from '../../../../graphdebate/src/components/Layout/BlockLastTopics';
import { BlockSidebarFeatured } from '../../../../graphdebate/src/components/Layout/BlockSidebarFeatured';
import type { LayoutBlock } from '../../types/layout';
import { html } from '../../utils/dom';
import { BlockArticle } from './BlockArticle';
import { handleLayoutBlockDisplayClass } from './blockCheck';

interface BlockProps {
  block: LayoutBlock;
  stickyOffset?: number;
}

export const Block: React.FC<BlockProps> = memo(({ block, stickyOffset }) => {
  const blockRef = useRef<HTMLDivElement | null>(null);
  const { type, content, locate, sticky: isSticky } = block;

  let element = <></>;

  if (!content || typeof content !== 'string') {
    /* if (block.type === 'look-a-like-from-external-url' && BlockExternal) {
      element = BlockExternal;
    } else */ if (type === 'similar-articles') {
      element = <BlockArticle block={block} />;
    } else if (type === 'last-topics' && locate === 'right') {
      element = (
        <BlockLastTopics
          className="pb-2 lg:[&_.CreateTopicButton_button>div]:gap-x-1"
          block={block}
          itemTitleClassName="md:text-base md:font-normal md:leading-5"
          withLinkToAllCTA
          isResponsive={false}
          isSidebar={true}
        />
      );
    } else if (type === 'last-topics') {
      element = (
        <BlockLastTopics className="lg:px-4 pb-2 max-w-5xl m-auto" block={block} withLinkToAllCTA />
      );
    } else if (type === 'featured') {
      element = (
        <BlockFeatured className="px-4 pb-2 max-w-5xl m-auto" block={block} withLinkToAllCTA />
      );
    } else if (type === 'sidebar-featured') {
      element = <BlockSidebarFeatured className="px-4 pb-2" block={block} />;
    } else {
      element = <>{content}</>;
    }
  }

  const blockCss =
    type !== 'sidebar-featured' && // TODO: fix for now. Waiting BO update to remove it
    (block.css_prefixed || '')
      .replace(/ :scope/g, '') // allow passing ":scope" to reference block container
      .replace(/(\r\n|\n|\r)/gm, ''); // remove line breaks

  const blockContentJsx = content && typeof content === 'string' ? html(content) : element;

  return (
    <>
      {!!blockContentJsx && (
        <div
          id={`block-${block._id}`}
          ref={blockRef}
          className={twMerge(
            'layout-block',
            `locate-${locate}`,
            ['left', 'right'].includes(locate) && 'locate-sidebar',
            handleLayoutBlockDisplayClass(block),
            isSticky ? 'md:sticky bg-white md:z-[10]' : 'overflow-hidden',
          )}
          {...(isSticky && { style: { top: `${stickyOffset || 0}px` } })}
        >
          {blockContentJsx}
        </div>
      )}
      {blockCss ? (
        <Head>
          <style>{blockCss}</style>
        </Head>
      ) : null}
    </>
  );
});
